import "./LinkTree.css";
import React, { useEffect, useRef } from "react";
import { analytics } from "../../app/service/firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Button, Dropdown } from "antd";
export default function LinkTree() {
  const [isMenuVisible, setMenuVisible] = React.useState(true);
  const links = [
    {
      name: "Master Frontend Dev in 2024",
      href: "https://smrth-tech.notion.site/Master-Frontend-Dev-in-2024-471d5c911f154416a2eed68d0b64d670",
      key: "frontend_2024",
      emoji: "🔧",
    },
    {
      name: "Must have VS code extensions for every developer",
      href: "https://smrth-tech.notion.site/Must-have-VS-Code-Extensions-0f6113c8ccd24ea081c367c067c4bb33",
      key: "vscode_extensions",
      emoji: "🔧",
    },
    {
      name: "Master GIT -  beginner friendly [TRENDING]",
      href: "https://smrth-tech.notion.site/Master-GIT-beginner-friendly-2679dc340f5a43db85251dd59d545182?pvs=74",
      key: "github_guide",
      emoji: "🦸‍♂️🌟",
    },
    {
      name: "YouTube Videos Perfect for Beginners to Learn Web Development [TRENDING]",
      href: "https://smrth-tech.notion.site/YouTube-Videos-Perfect-for-Beginners-to-Learn-Web-Development-25a18aadcd9e4d399eca8c81c8255dc0",
      key: "youtube_videos_web_dev",
      emoji: "🌐",
    },
    {
      name: "How I got my first internship in my first year?",
      href: "https://smrth-tech.notion.site/How-I-got-my-first-internship-in-first-year-from-a-tier-3-college-2ce435964df54ea7bb30b5930c873f4d",
      key: "first_internship",
      emoji: "🌐",
    },
    {
      name: "Beginner Friendly DSA guide",
      href: "https://smrth-tech.notion.site/Beginner-Friendly-DSA-guide-go-from-zero-to-hero-bc0d531717074329a57a4ccb4eb32fe5",
      key: "dsa_resouces",
      emoji: "🦸",
    },
    {
      name: "Must know resources for a backend developer",
      href: "https://smrth-tech.notion.site/If-you-are-a-backend-developer-you-must-know-these-resources-d2240725725b4b9d97dcddd4d190199a",
      key: "backend_developer_resources",
      emoji: "⚙️",
    },
    {
      name: "Must know tools for a web developer",
      href: "https://smrth-tech.notion.site/Must-know-tools-for-a-web-developer-2b13b76e23d6490bb03884a29daeddcc",
      key: "web_developer_tools",
      emoji: "🔧",
    },

    {
      name: "5 things I wish I knew before as a software engineer",
      href: "https://smrth-tech.notion.site/5-things-I-wish-I-knew-before-as-a-software-0c6ab33177cd4cc787287ee3c620a179",
      key: "5_things_i_wish_i_knew_before_as_a_software_engineer",
      emoji: "💡",
    },
    {
      name: "How I plan and build a feature",
      href: "https://smrth-tech.notion.site/How-do-I-plan-and-start-building-a-feature-8e8ee0eb716d4d1db1a83c58c0026fd0",
      key: "how_i_plan_and_build_a_feature",
      emoji: "🛠️",
    },
    {
      name: "The Power of Starting Today: A Journey Towards Achievement",
      href: "https://smrth-tech.notion.site/The-Power-of-Starting-Today-A-Journey-Towards-Achievement-efd4880c0f784986ab5b7ebc77948c27",
      key: "power_of_starting_today",
      emoji: "🚀",
    },
  ];

  console.log(links);

  const trackViewTime = (sectionName) => {
    logEvent(analytics, "section_view", { section_name: sectionName });
  };

  function handleClick(button_name) {
    logEvent(analytics, "button_click", { button_name: button_name });
  }
  useEffect(() => {
    logEvent(analytics, "link_page_visit");
  }, []);
  useEffect(() => {
    trackViewTime("link_page");

    return () => {
      logEvent(analytics, "exit_section", { section_name: "link_page" });
    };
  }, []);

  return (
    <div className="bg-[#0D0D0D] scroll-smooth relative min-h-[100vh] pt-5">
      <div className="px-[28px]">
        {isMenuVisible && (
          <div className="sticky top-0 z-[100]">
            <div className="sm:text-end z-[100] text-center pt-8 mr-8 absolute sm:top-1 sm:right-1 ">
              <>
                <div className="flex justify-center items-center text-[#fff] custom-fonts text-[14px] cursor-pointer">
                  <p
                    onClick={() => {
                      handleClick("redirect_to_main_page_from_links");
                      window.open("https://smrth.tech/", "_blank");
                    }}
                  >
                    Check out my portfolio
                  </p>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col min-h-[100vh] justify-center sm:overflow-hidden w-[100%] top-0 left-0 py-[100px] p-[10px] LinkTree color-white">
        {/* each link object is rendered as an anchor tag */}
        {links.map(({ name, href, key, emoji }) => (
          <a
            href={href}
            className="myLink"
            target="_blank"
            onClick={() => handleClick(key)}
          >
            {name} {emoji}
          </a>
        ))}
      </div>
    </div>
  );
}
