import React from "react";
import Home from "../Home/Home";
import LinkTree from "../LinkTree/LinkTree";

function extractSubdomain(url) {
  const regex =
    /^(?:https?:\/\/)?(?:www\.)?([^:/?#\s]+)\.smrth\.tech(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

function HomeMaster() {
  const url = window.location.href;
  const subdomain = extractSubdomain(url);
  console.log(subdomain);
  if (subdomain === "links" || subdomain.includes("links")) return <LinkTree />;
  if (subdomain === "blogs") return <div>Coming Soon</div>;
  return <Home />;
}

export default HomeMaster;
