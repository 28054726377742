import React from "react";
import { Route, Routes } from "react-router-dom";
import LinkTree from "../pages/LinkTree/LinkTree";
import HomeMaster from "../pages/HomeMaster/HomeMaster";
import Home from "../pages/Home/Home";
import { ROUTES } from "./RouterConfig";

const Router = () => {
  const RouteWithRole = ({ Element }) => {
    return (
      <>
        <Element />
      </>
    );
  };

  return (
    <div>
      <Routes>
        <Route
          exact
          path={ROUTES.Home}
          element={<RouteWithRole Element={HomeMaster} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Links}
          element={<RouteWithRole Element={LinkTree} />}
        ></Route>
        <Route
          exact
          path="*"
          element={<RouteWithRole Element={Home} />}
        ></Route>
        {/* <Route exact path={ROUTES.About} element={<RouteWithRole Element={About} />}></Route> */}
      </Routes>
    </div>
  );
};

export default Router;
